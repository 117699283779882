import * as React from 'react';
import Layout from "../../components/Layout";
import { Helmet } from "react-helmet";

const ExecutiveAssistant = () => {
    return (
  <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Career Opportunities | Digital Marketing, Web Design, Web Development</title>

        <meta
          name="description"
          content="We are rapidly growing. Check out our available career opportunities in digital marketing, web design, web development, and sales."
        />
        <meta property="og:locale" content="en_US"></meta>
        <meta property="og:type" content="website"></meta>
        <meta
          property="og:title"
          content="Consult FGC | Digital Marketing Agency | Website Design & Development"
        ></meta>
        <meta
          property="og:description"
          content="Consult FGC is a boutique digital marketing agency specializing in SEM, SEO, website design & development, and geofencing marketing & advertising."
        ></meta>
        <meta
          property="og:site_name"
          content="Consult FGC | Digital Marketing Agency | Website Design & Development"
        ></meta>
        <meta name="twitter:card" content="summary_large_image"></meta>
        <meta
          name="twitter:description"
          content="Consult FGC is a boutique digital marketing agency specializing in SEM, SEO, website design & development, and geofencing marketing & advertising."
        ></meta>
        <meta
          name="twitter:title"
          content="Consult FGC | Digital Marketing Agency | Website Design & Development"
        ></meta>
        <link
          rel="icon"
          href="https://consultfgc.netlify.app/static/ConsultFGC_Logo_footer-8b0c6cdcc10d6cc56b61a7b78ed851b7.png"
        />
      </Helmet>
    
 <div class="bg-white py-20 md:py-32">
        <div class="mx-auto px-7 md:max-w-5xl">
          
         <div class="text-center sm:text-left sm:flex sm:justify-start items-center">
         <div class="">
            <h1 class ="text-2xl md:text-5xl font-bold text-black ">
            Executive Assistant
    
          </h1>
          </div>
          
         </div>
          <div class="sm:mt-5 sm:text-lg sm:max-w-5xl sm:mx-auto"></div>
              <h2 class="text-1xl md:text-1xl font-medium text-black ">
              <div class="pt-10 pb-0 ">
              We are currently looking for an Executive Assistant to work with the CEO of a highly valued tech start-up. Candidates must be able to demonstrate longevity in positions supporting extraordinarily busy executives. Ideal candidates will have a keen intuition, be proactive with their thinking, and be able to juggle many competing priorities at once. The position could be PT or FT for the right candidate and could be structured to work around your schedule.
                </div>
                </h2>

                <h3 class="text-1xl md:text-1xl font-normal text-black ">
                <div class="pt-10 pb-0">
                Daily tasks will include assisting the CEO with incoming and outgoing emails, calls, and scheduling on behalf of the CEO, for both work and personal. Managing and executing calendar meetings and appointments, and participating in calls with the CEO to determine deliverables, and ensure follow-up action has been taken.
                </div>
                
                
                <div class="pt-10 pb-0">
                Other responsibilities will include various research projects centered around digital advertising, marketing, and other technology areas. Collaborating with the CEO’s wife on personal schedule and children’s schedules. Project Manager on a daily/ongoing basis ensuring all aspects of the CEO’s business and personal life are in coordination.
                </div>
                
                <div class="pt-10 pb-0">
                This job will be based in Dunedin, and applying candidates will need to be able to work from the CEO’s home and/or home office, and may need to be on the road occasionally with the CEO when he travels. There will be much work from home opportunities (if desired) during times the CEO is traveling and does not require assistance. This is a perfect opportunity for a self-motivated, independent, organized, and well-written/spoken individual.
                </div>
                

                <p class="text-1xl md:text-1xl font-bold text-black ">
                <div class="pt-10 pb-4">
                Responsibilities:
                </div>
                </p>
            

                <div class="ml-10">

                <div>
                <span class="inline-flex items-center">
                <svg class="-ml-0.5 mr-1.5 h-2 w-2 text-black" fill="currentColor" viewBox="0 0 8 8">
                <circle cx="4" cy="4" r="3" />
                </svg>
                Calendar management
                </span>
                </div>
                
                <div>
                <span class="inline-flex items-center">
                <svg class="-ml-0.5 mr-1.5 h-2 w-2 text-black" fill="currentColor" viewBox="0 0 8 8">
                <circle cx="4" cy="4" r="3" />
                </svg>
                Travel coordination
                </span>
                </div>

                <div>
                <span class="inline-flex items-center">
                <svg class="-ml-0.5 mr-1.5 h-2 w-2 text-black" fill="currentColor" viewBox="0 0 8 8">
                <circle cx="4" cy="4" r="3" />
                </svg>
                Expense management
                </span>
                </div>

                <div>
                <span class="inline-flex items-center">
                <svg class="-ml-0.5 mr-1.5 h-2 w-2 text-black" fill="currentColor" viewBox="0 0 8 8">
                <circle cx="4" cy="4" r="3" />
                </svg>
                Overall office and CEO personal management
                </span>
                </div>

                <div>
                <span class="inline-flex items-center">
                <svg class="-ml-0.5 mr-1.5 h-2 w-2 text-black" fill="currentColor" viewBox="0 0 8 8">
                <circle cx="4" cy="4" r="3" />
                </svg>
                Company meeting coordination
                </span>
                </div>

                <div>
                <span class="inline-flex items-center">
                <svg class="-ml-0.5 mr-1.5 h-2 w-2 text-black" fill="currentColor" viewBox="0 0 8 8">
                <circle cx="4" cy="4" r="3" />
                </svg>
                Annual conference and other firm event planning
                </span>
                </div>

                <div>
                <span class="inline-flex items-center">
                <svg class="-ml-0.5 mr-1.5 h-2 w-2 text-black" fill="currentColor" viewBox="0 0 8 8">
                <circle cx="4" cy="4" r="3" />
                </svg>
                CEO’s personal schedule management and children’s pick-up/drop off
                </span>
                </div>
                </div>
                
                <p class="text-1xl md:text-1xl font-bold text-black ">
                <div class="pt-10 pb-4">
                Qualifications:
                </div>
                </p>
                
            

                 <div class="ml-10">

                <div>
                <span class="inline-flex items-center">
                <svg class="-ml-0.5 mr-1.5 h-2 w-2 text-black" fill="currentColor" viewBox="0 0 8 8">
                <circle cx="4" cy="4" r="3" />
                </svg>
                Associates degree or higher (or currently in school pursuing a degree)                
                </span>
                </div>
                
                <div>
                <span class="inline-flex items-center">
                <svg class="-ml-0.5 mr-1.5 h-2 w-2 text-black" fill="currentColor" viewBox="0 0 8 8">
                <circle cx="4" cy="4" r="3" />
                </svg>
                 2+ Years of experience in similar professional environment
                </span>
                </div>

                <div>
                <span class="inline-flex items-center">
                <svg class="-ml-0.5 mr-1.5 h-2 w-2 text-black" fill="currentColor" viewBox="0 0 8 8">
                <circle cx="4" cy="4" r="3" />
                </svg>
                 Proficient in Microsoft Office/Google Workspace suite
                </span>
                </div>

                <div>
                <span class="inline-flex items-center">
                <svg class="-ml-0.5 mr-1.5 h-2 w-2 text-black" fill="currentColor" viewBox="0 0 8 8">
                <circle cx="4" cy="4" r="3" />
                </svg>
                Prior experience supporting senior-level professionals, managing multiple priorities, coordination, and logistics
                 </span>
                </div>
                </div>

                <div class="pt-10 pb-0">
                Job Type: Part-Time or Full-time
                </div>

                <div class="pt-4 pb-0">
                Pay: From $18.50-$22.00 per hour
                </div>

                <div class="pt-4 pb-0">
                Benefits: 
                </div>
            
       
        <div class="ml-10">

                <div> 
                <span class="inline-flex items-center">
                <svg class="-ml-0.5 mr-1.5 h-2 w-2 text-black" fill="currentColor" viewBox="0 0 8 8">
                <circle cx="4" cy="4" r="3" />
                </svg>
                Flexible schedule
                </span>
                </div> 

                <div> 
                <span class="inline-flex items-center">
                <svg class="-ml-0.5 mr-1.5 h-2 w-2 text-black" fill="currentColor" viewBox="0 0 8 8">
                <circle cx="4" cy="4" r="3" />
                </svg>
                Monthly Gas Stipend
                </span>
                </div> 

                <div> 
                <span class="inline-flex items-center">
                <svg class="-ml-0.5 mr-1.5 h-2 w-2 text-black" fill="currentColor" viewBox="0 0 8 8">
                <circle cx="4" cy="4" r="3" />
                </svg>
                Bonuses and Profit Sharing (after 90 days)
                </span>
                </div> 
        </div>

                <div class="pt-4 pb-0">
                Schedule:

         <div class="ml-10">
                <div>
                <span class="inline-flex items-center">
                <svg class="-ml-0.5 mr-1.5 h-2 w-2 text-black" fill="currentColor" viewBox="0 0 8 8">
                <circle cx="4" cy="4" r="3" />
                </svg>
                Day Shift 
                </span>
                </div>

                <div>
                <span class="inline-flex items-center">
                <svg class="-ml-0.5 mr-1.5 h-2 w-2 text-black" fill="currentColor" viewBox="0 0 8 8">
                <circle cx="4" cy="4" r="3" />
                </svg>
                Monday to Friday
                </span>
                </div>
        </div>
                
               

                <div class="pt-4 pb-10">
                Work Location: Dunedin, FL 34698
                </div>
                
                
                
               
                
                </div>
                

                
                </h3>
               
                <div class="mt-12 md:flex md:justify-start ">
                    <div class="text-center">
                      <a
                        href="https://www.indeed.com/viewjob?t=ptft+executive+assistant&jk=fa9f958302a7e152&_ga=2.98098074.483469870.1660935015-1552544337.1660592135"
                        class="inline-flex text-sky-100 border-2 border-sky-600 bg-sky-600  font-medium rounded-lg text-xl px-20 md:px-28 py-4 hover:opacity-80 focus:outline-none"
                      >
                        Apply Now 
                      </a>
                    </div>
                    </div>       

        


         </div>

         

</div>

         

  </Layout>

);
};

export default ExecutiveAssistant;